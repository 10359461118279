import { components } from "react-select";

const CheckboxOption = (props) => {

    return (
        <div>
            <components.Option className="flex" {...props} >
                <div className="flex items-start gap-2">
                    <input
                        className="w-4 h-4 flex mt-1 justify-center items-center"
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </div>
            </components.Option>
        </div>
    );
};

export default CheckboxOption;