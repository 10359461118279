import React, { useMemo, useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { AsyncPaginate } from "react-select-async-paginate";
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../tailwind.config'
import Tooltip from '../../atoms/Tooltip'
import IconChevronDown from 'atoms/SvgIcons/IconChevronDown.svg';
import IconSearchThick from 'atoms/SvgIcons/IconSearchThick.svg';
import IconInformation from 'atoms/SvgIcons/IconInformation.svg';
import CheckboxOption from './CheckboxOption';
import IconSearch from 'atoms/SvgIcons/IconSearch.svg';
import SelectContainer from './SelectContainer';
import RSClearIndicator from './RSClearIndicator';
import RSInput from './RSInput';
import RSMultiValueRemove from './RSMultiValueRemove';
import ValidateUserInput from '@/components/common/ValidateUserInput';
import useWindowSize from 'customHook/useWindowSize';

const {
    theme: {
        colors: {
            primary1: {
                50: primary1_50,
                100: primary1_100,
                500: primary1_500,
                200: primary1_200,
            },
            gray: {
                100: gray_100,
                200: gray_200,
                300: gray_300,
                400: gray_400,
                500: gray_500,
                500: gray_600,
                800: gray_800,
                900: gray_900,
            },
            basic: {
                white: basic_white
            },
            semantic: { error_base }
        }
    }
} = resolveConfig(tailwindConfig);

const miniDropdownHeight = 30;

const DropdownTheme = {
    BUTTON: {
        placeholderColor: '#fff',
        textColor: '#fff',
        bgColor: '#24ACDA',
        arrowColor: 'text-basic-whitePure'
    }
}

const ReactSelect = ({ className = '', isMulti, isDisabled, defaultValue, error, register, id, isRequiredField, placeholder,
    options, height = 48, lineHeight, loadOptions, isTooltip, name, fontSize, isHideStickyPlaceholder = false, size, dropdownTheme = 'regular',
    iconIndicator, customZIndex, inputDataType, mandatory, characterLimit, minimumLength, maximumLength, validationHandler, handleKeyPress, handlePaste,
    selectedValueFontSize = 16, optionsFontSize = null, dropdownHeight = 48, optionPadding = '14px 16px', menuWidth, isDropdownIcon = true , isShowPlaceholder = false , dropDownIcon, paddingLeft, padding, ...rest }) => {
        const THEME = DropdownTheme[dropdownTheme];

    const [width] = useWindowSize();
        
    const customStyles = useMemo(() => ({
        ...(isMulti
            ? {
                valueContainer: (base, state) => {
                    return {
                        ...base,
                        paddingLeft: paddingLeft ?? 10,
                        padding: padding ?? 5,
                        backgroundColor: 'transparent',
                        '&:hover': {
                            color: "inherit"
                        }
                    }
                }
            }
            : {
                valueContainer: (base, state) => {
                    return {
                        ...base,
                        padding: size == 'small' ? '0 0 0 8px' : '0 0 0 16px',
                        backgroundColor: 'transparent',
                        minHeight: size == 'small' ? (miniDropdownHeight - 2) : null,
                        '&:hover': {
                            color: "inherit"
                        }
                    }
                }
            }
        ),

        option: (provided, state) => {

            return {
                ...provided,
                color: gray_800,
                backgroundColor: state.isDisabled ? gray_100 : state.isSelected ? primary1_50 : state.isFocused ? primary1_50 : basic_white,
                padding: size == 'small' ? '4px 8px' : optionPadding,
                fontFamily: "'Inter', sans-serif",
                fontSize: size == 'small' ? 14 : optionsFontSize,
                cursor: state.isDisabled ? 'not-allowed' : null,
                '&:active': { backgroundColor: primary1_100 }
            }
        },
        control: (base, state) => {
            return {
                ...base,
                boxShadow: null,
                ...(!isMulti && { minHeight: height, lineHeight: lineHeight ? lineHeight + 'px' : null }),
                borderRadius: size == 'small' ? 4 : 8,
                overflow: 'hidden',
                cursor: state.isDisabled ? 'not-allowed' : 'default',
                backgroundColor: state.isDisabled ? gray_100 : (THEME?.bgColor || basic_white),
                borderColor: error ? error_base : state.isDisabled ? gray_300 : state.isFocused ? primary1_500 : gray_300,
                "&:hover": {
                    borderColor: error ? error_base : state.isDisabled ? gray_300 : state.isFocused ? primary1_500 : gray_500
                },
                ...(state.isDisabled && {
                    pointerEvents: "auto",
                    cursor: "not-allowed"
                }),
                minHeight: size == 'small' ? miniDropdownHeight : dropdownHeight,
            }
        },
        multiValueLabel: (base, state) => {
            return {
                ...base,
                fontFamily: "'Inter', sans-serif",
                color: gray_800,
                fontSize: 12,
                lineHeight: '16px',
                fontWeight: 500,
                padding: '8px 0 8px 8px',
                paddingLeft: '8px',
            }
        },
        multiValue: (base, state) => {
            return {
                ...base,
                borderRadius: 8,
            }
        },
        multiValueRemove: (base, state) => {
            return {
                ...base,
                padding: 0,
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: "inherit"
                }
            }
        },
        menu: (base, state) => {
            return {
                ...base,
                ...(menuWidth ? { width: menuWidth } : {}),
                borderRadius: 4,
                overflow: 'hidden',
                border: '1px solid ' + gray_200,
            }
        },
        ...(!isMulti && {
            dropdownIndicator: (provided, state) => ({
                ...provided,
                color: gray_600,
                padding: 2,
                transition: "all .2s ease",
                transform: state.selectProps.menuIsOpen && "rotate(180deg)",
                minHeight: size == 'small' ? (miniDropdownHeight - 2) : 30,
            }),
        }),
        ...(!isMulti && {
            placeholder: (provided, state) => ({
                ...provided,
                color: (THEME?.placeholderColor || gray_400),
                fontSize: size == 'small' ? 14 : 16,
                lineHeight: '24px',
            }),
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: gray_900,
            fontSize: size == 'small' ? 14 : selectedValueFontSize,
            lineHeight: '24px',
            color: THEME?.textColor || null,
        }),
        menuPortal: base => ({ ...base, zIndex: customZIndex ?? 10 }),
        input: base => ({
            ...base,
            ...(size == 'small'
                ? { margin: 0, padding: 0, fontSize: 14 }
                : null
            )
        })

    }), [error]);

    return (
        <div className={`${className} dropdown`}>
            {!loadOptions
                ? <Select
                    menuPortalTarget={typeof window !== 'undefined' ? document.querySelector('body') : null}
                    styles={error ? { ...customStyles, } : customStyles}
                    isMulti={isMulti}
                    isDisabled={isDisabled}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={options}
                    {...(isMulti ? isShowPlaceholder ? { placeholder } : { placeholder: null } : { placeholder })}
                    theme={(theme) => {
                        return {
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: 'red',
                                primary50: gray_200,
                                primary: primary1_500,
                            },
                            borderRadius: 0,
                        }
                    }}
                    aria-label={name}
                    name={name}
                    defaultValue={defaultValue}
                    components={{
                        ...((isMulti && isDropdownIcon)
                            ? { DropdownIndicator: () => null }
                            : {
                                DropdownIndicator: (props) => {
                                    const toggleColor = props.selectProps.menuIsOpen ? 'text-primary1-500' : 'text-gray-400';
                                    const iconColor = THEME?.arrowColor
                                        ? 'text-basic-whitePure'
                                        : toggleColor;
                                    return (
                                        <div className='mr-3'>
                                            <components.DropdownIndicator {...props}>
                                                <IconChevronDown className={`${iconColor} flex-shrink-0 w-3`} />
                                            </components.DropdownIndicator>
                                        </div>
                                    );
                                }
                            }),
                        IndicatorSeparator: () => null,
                        ...((isMulti && isDropdownIcon) && {
                            Input: (props) => {
                                if (props.isHidden) {
                                    return <div><components.Input {...props} /></div>;
                                }
                                return (
                                    <div className={`${props.hasValue ? 'min-h-9.5' : 'min-h-10.5'} w-full flex items-center px-1`}>
                                        <IconSearchThick className="flex-shrink-0 w-6 fill-gray-400 mr-2" />
                                        {(!props.value) && (
                                            <div className='relative'>
                                                <div className={`${props.isDisabled ? 'text-gray-500' : 'text-gray-400'} absolute left-0.5 transform -translate-y-1/2 font-Inter font-normal text-sm`}>Search</div>
                                            </div>
                                        )}

                                        <components.Input className='w-full' {...props} />
                                    </div>
                                );
                            }
                        }),
                        ...(!isHideStickyPlaceholder && { SelectContainer })
                        ,
                        MultiValueRemove: RSMultiValueRemove,
                        ClearIndicator: RSClearIndicator,
                        ...(isTooltip && {
                            Option: (props) => {
                                const listRef = useRef(null)
                                const [counter, setCounter] = useState(0)
                                useEffect(() => setCounter(old => old + 1), [])
                                return (

                                    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', }} ref={listRef}>
                                        <components.Option {...props} />
                                        <div className='absolute right-0 px-3'>
                                            {listRef.current && <Tooltip tooltipContent={props.data.tooltip} overlayWidth={listRef.current.clientWidth}>
                                                <IconInformation className="w-5 text-gray-900 hover:text-primary1-500" />
                                            </Tooltip>}
                                        </div>
                                    </div>




                                );
                            }
                        }),

                    }}
                    {...rest}
                />
                : <AsyncPaginate
                    menuPortalTarget={typeof window !== 'undefined' ? document.querySelector('body') : null}
                    styles={error ? { ...customStyles, } : customStyles}
                    loadOptions={error?.type != "invalid" ? loadOptions : () => ({ options: [], hasMore: false })}
                    {...(error && { noOptionsMessage: () => null })}
                    isMulti={isMulti}
                    aria-label={name}
                    name={name}
                    isDisabled={isDisabled}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={placeholder}
                    {...(validationHandler && { ...validationHandler })}
                    theme={(theme) => {
                        return {
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: 'red',
                                primary50: gray_200,
                                primary: primary1_500,
                            },
                            borderRadius: 0,
                            dropdownTheme,
                        }
                    }}
                    components={{
                        ...(isMulti
                            ? { DropdownIndicator: () => null, Placeholder: () => null }
                            : {
                                DropdownIndicator: (props) => {
                                    return (
                                        <div className='mr-3'>
                                            <components.DropdownIndicator {...props}>
                                                <IconChevronDown className={`${props.selectProps.menuIsOpen ? 'text-primary1-500' : 'text-gray-400'} flex-shrink-0 w-3`} />
                                            </components.DropdownIndicator>
                                        </div>
                                    );
                                }
                            }),
                        IndicatorSeparator: () => null,
                        ...(isMulti && {
                            Input: RSInput,
                        }),
                        MultiValueRemove: RSMultiValueRemove,
                        ClearIndicator: RSClearIndicator,
                        ...(dropdownTheme === 'CHECKBOX' && dropDownIcon !== 'downArrow' ? (
                            {
                                DropdownIndicator: (props) => {
                                    return (
                                        <IconSearch className="flex-shrink-0 w-4 fill-gray-400 mr-3" />
                                    )
                                },
                                MultiValue: (props) => {
                                    return <div></div>
                                },
                            }
                        ) : 
                        (
                            {
                                DropdownIndicator: (props) => {
                                    return (
                                        <div className='mr-3'>
                                            <components.DropdownIndicator {...props}>
                                                <IconChevronDown className={`${props.selectProps.menuIsOpen ? 'text-primary1-500' : 'text-gray-400'} flex-shrink-0 w-3`} />
                                            </components.DropdownIndicator>
                                        </div>
                                    )
                                },
                                MultiValue: (props) => {
                                    return <div></div>
                                },
                            }
                        )
                    ),
                        ...(dropdownTheme === 'CHECKBOX' && { Option: CheckboxOption, }),
                        ...((!isHideStickyPlaceholder && dropdownTheme !== 'regular') && { SelectContainer })
                    }}
                    {...(dropdownTheme === 'CHECKBOX' && { closeMenuOnSelect: false, hideSelectedOptions: false, blurInputOnSelect: false })}
                    {...rest}
                />
            }

            {error && (
                <div className="text-semantic-error_base text-sm mt-2 mb-14px">
                    {error.message}
                </div>
            )}
        </div>
    )
}

export default ValidateUserInput(ReactSelect);